<template>
  <v-card justify="center">
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      :id="formId"
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          v-bind="activatorProps"          
          width="100%"          
          >{{ Text("ml_114669", "Scan") }}</v-btn
        >
      </template>
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              Text("ml_114669", "Scan") + " " + labelText
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="submit" v-t="'ml_114657'"> Okay </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <qrcode-stream :camera="camera" @detect="onDecode" @init="onInit">
            <div v-if="validationSuccess" class="validation-success" v-t="'ml_114664'">
              Scanning is completed
            </div>
            <div v-if="validationFailure" class="validation-failure">
              {{ labelText }} not confirmed
            </div>
            <div v-if="validationPending" class="validation-pending" v-t="'ml_114665'">
              Scanning in progress
            </div>
          </qrcode-stream>
          <div v-if="decodedContent.length > 0">
            <span> {{ Text("ml_114721", "Scaned") + " " + labelText }}: </span>
            <span>
              <b>&nbsp;{{ decodedContent }}</b
              >.
            </span>
          </div>
          <p class="red-error">{{ cameraError }}</p>
          <v-btn
            color="primary"
            dark
            block
            @click="retry"
            v-if="decodedContent.length > 0 && camera === 'off'"
            v-t="'ml_114666'"
            >Retry</v-btn
          >
          <br />
          <v-btn
            color="primary"
            dark
            block
            @click="submit"
            v-if="decodedContent.length > 0 && 1 > 2"
            v-t="'ml_114639'"
            >Next</v-btn
          >
          <v-divider></v-divider>
        </v-card>
      </template>
      
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { ConsoleLog, Timeout, Text, IsNullOrEmpty } from "@/utility";
import Vue from "vue";
import { QrcodeStream } from "vue-qrcode-reader";

import { defineComponent } from 'vue';
export default defineComponent({
  emits: ['handleChange'],
  components: { QrcodeStream },
  props: {
    formId: String,
    labelText: String,
    initialValue: String,
    handleChange: { type: Function },
  },
  data() {
    return {
      showDialog: false,
      cameraError: "",
      decodedContent: "",
      isValid: { type: Boolean, default: undefined },
      camera: "auto",
    };
  },
  computed: {
    validationSuccess(): boolean {
      return (this as any).isValid === true;
    },
    validationFailure(): boolean {
      return (this as any).isValid === false;
    },
    validationPending(): boolean {
      return (this as any).isValid === undefined && (this as any).camera === "off";
    },
  },
  methods: {
    Text,
    resetValidationState(): void {
      const self = this as any;
      self.isValid = undefined;
      self.message = "";
    },
    retry(): void {
      const self = this as any;
      self.resetValidationState();
      self.turnCameraOn();
    },
    submit(): void {
      const self = this as any;
      self.turnCameraOff();
      this.$emit("handleChange", self.decodedContent);
      self.showDialog = false;
    },
    // onChanging(value1: string) {
    //   ConsoleLog({ onChanging: value1 });
    //   this.$emit("input", value1);
    // },
    turnCameraOn() {
      (this as any).camera = "auto";
    },
    turnCameraOff() {
      (this as any).camera = "off";
    },
    async onDecode(scannedValue: any) {
      console.log({ scannedValue: scannedValue });
      const self = this as any;
      self.decodedContent = scannedValue[0].rawValue.toUpperCase();

      await Timeout(1000);
      self.isValid = true;
      this.submit();
      // self.turnCameraOff();
      // self.isValid = scannedValue.startsWith("http");
    },
    async onInit(promise: any) {
      // promise.then(console.log).catch(console.error);
      try {
        await promise;
        // promise.then(console.log);
      } catch (error: any) {
        if (error.name === "NotAllowedError") {
          this.cameraError = Text(
            "ml_114670",
            "ERROR: you need to grant camera access permission"
          );
        } else if (error.name === "NotFoundError") {
          this.cameraError = Text("ml_114671", "ERROR: no camera on this device");
        } else if (error.name === "NotSupportedError") {
          this.cameraError = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.cameraError = Text("ml_114672", "ERROR: is the camera already in use?");
        } else if (error.name === "OverconstrainedError") {
          this.cameraError = Text(
            "ml_114673",
            "ERROR: installed cameras are not suitable"
          );
        } else if (error.name === "StreamApiNotSupportedError") {
          this.cameraError = Text(
            "ml_114674",
            "ERROR: Stream API is not supported in this browser"
          );
        } else if (error.name === "InsecureContextError") {
          this.cameraError = Text(
            "ml_114675",
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP."
          );
        } else {
          this.cameraError = `${Text(
            "ml_114676",
            "ERROR: Stream API is not supported in this browser"
          )} (${error.name})`;
        }
      }
    },
    close() {
      const self = this as any;
      self.turnCameraOff();
      self.showDialog = false;
    },
  },
  beforeMount() {
    ConsoleLog({ QrScanerbeforeMount: this.initialValue });
    if (!IsNullOrEmpty(this.initialValue)) {
      this.decodedContent = this.initialValue;
    } else {
      this.decodedContent = "";
    }
  },
});
</script>
<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
