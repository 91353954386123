
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import english from '@/locales/en-US.json'
import { STR_DEFAULT_LANG, STR_LANG_ZH_CN } from './models/consts'
import zhcn from '@/locales/zh-CN.json'

const defaultLang = 'en-US'

const defaultMessages = { 
  'en': english,
  'en-US': english,
  STR_LANG_ZH_CN: zhcn,
  'zh': zhcn
}

const numberFormatsTemplate = {
  '2dec': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  '3dec': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  '4dec': { minimumFractionDigits: 4, maximumFractionDigits: 4 }
}

const dateTimeFormatsTemplate = {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  med: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  }
}

const numberFormats = { 'en-US': numberFormatsTemplate } as any,// eslint-disable-line
  datetimeFormats = { 'en-US': dateTimeFormatsTemplate } as any;// eslint-disable-line

// const i18n = createI18n({
//   globalInjection: true,
//   legacy: false, //default is true
//   locale: defaultLang,
//   fallbackLocale: defaultLang,
//   allowComposition: true,
//   datetimeFormats,
//   numberFormats,
//   messages: defaultMessages,
//   missingWarn: false, // Composition  [intlify] Not found 'ml_107448' key in 'en' locale messages.
// })

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
	locale: STR_DEFAULT_LANG,
	fallbackLocale: STR_DEFAULT_LANG,
	datetimeFormats,
	numberFormats,
	defaultMessages,
  missingWarn: false, // Composition  [intlify] Not found 'ml_107448' key in 'en' locale messages.
  silentTranslationWarn: true,
  fallbackWarn: false,
})

const intialLanguage = (lang: string, messages: any) => { // eslint-disable-line
  numberFormats[lang] = numberFormatsTemplate
  datetimeFormats[lang] = dateTimeFormatsTemplate
  i18n.global.setLocaleMessage(lang, messages);
  i18n.global.locale.value = lang;
  // i18n.global.locale = lang;
  return setI18nLanguage(lang)
}

const setI18nLanguage = (lang: string) => {
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

// Set new locale.
(i18n as any).loadLanguageAsync = (locale: string) => {
  if (!i18n.global.availableLocales.includes(locale)) {
    return import(/* webpackChunkName: "lang-[request]" */ `./locales/${locale}`)
      .then(msgs => {
        return intialLanguage(locale, msgs);
      })
      .catch(() => {
        return intialLanguage(defaultLang, defaultMessages['en-US']);
      })
  }
}

export default i18n
