import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "validation-success"
}
const _hoisted_2 = {
  key: 1,
  class: "validation-failure"
}
const _hoisted_3 = {
  key: 2,
  class: "validation-pending"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "red-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_v_card, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
        fullscreen: "",
        "hide-overlay": "",
        scrollable: "",
        transition: "dialog-bottom-transition",
        id: _ctx.formId
      }, {
        activator: _withCtx(({ props: activatorProps }) => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, _mergeProps({
            color: "primary",
            dark: ""
          }, activatorProps, { width: "100%" }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.Text("ml_114669", "Scan")), 1)
            ]),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(({ isActive }) => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, {
                dark: "",
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    dark: "",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Text("ml_114669", "Scan") + " " + _ctx.labelText), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_toolbar_items, null, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                        dark: "",
                        text: "",
                        onClick: _ctx.submit
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" Okay ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])), [
                        [_directive_t, 'ml_114657']
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_qrcode_stream, {
                camera: _ctx.camera,
                onDetect: _ctx.onDecode,
                onInit: _ctx.onInit
              }, {
                default: _withCtx(() => [
                  (_ctx.validationSuccess)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
                        _createTextVNode(" Scanning is completed ")
                      ]))), [
                        [_directive_t, 'ml_114664']
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.validationFailure)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.labelText) + " not confirmed ", 1))
                    : _createCommentVNode("", true),
                  (_ctx.validationPending)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, _cache[4] || (_cache[4] = [
                        _createTextVNode(" Scanning in progress ")
                      ]))), [
                        [_directive_t, 'ml_114665']
                      ])
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["camera", "onDetect", "onInit"]),
              (_ctx.decodedContent.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.Text("ml_114721", "Scaned") + " " + _ctx.labelText) + ": ", 1),
                    _createElementVNode("span", null, [
                      _createElementVNode("b", null, " " + _toDisplayString(_ctx.decodedContent), 1),
                      _cache[5] || (_cache[5] = _createTextVNode(". "))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.cameraError), 1),
              (_ctx.decodedContent.length > 0 && _ctx.camera === 'off')
                ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    color: "primary",
                    dark: "",
                    block: "",
                    onClick: _ctx.retry
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Retry")
                    ])),
                    _: 1
                  }, 8, ["onClick"])), [
                    [_directive_t, 'ml_114666']
                  ])
                : _createCommentVNode("", true),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              (_ctx.decodedContent.length > 0 && 1 > 2)
                ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    color: "primary",
                    dark: "",
                    block: "",
                    onClick: _ctx.submit
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Next")
                    ])),
                    _: 1
                  }, 8, ["onClick"])), [
                    [_directive_t, 'ml_114639']
                  ])
                : _createCommentVNode("", true),
              _createVNode(_component_v_divider)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "id"])
    ]),
    _: 1
  }))
}