import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { store } from './store'
//import vuetify from './plugins/vuetify'
import { createI18n } from 'vue-i18n'
import * as consts from "@/models/consts"
// import { i18n } from './I18n'
import i18n from '@/I18n';
import { authService } from './services/AuthorService'

import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// import VueMaskDirective  from 'v-mask'
import VueSignaturePad from 'vue-signature-pad';

import { VTimePicker } from 'vuetify/labs/VTimePicker'
import VMask from "@ssibrahimbas/v-mask";
import { VDateInput } from 'vuetify/labs/VDateInput'

const app = createApp(App);

app.config.globalProperties.$authService = authService;
app.config.globalProperties.$lanCode = consts.STR_DEFAULT_LANG;
app.config.globalProperties.$isDebug = false;
app.config.globalProperties.$store = store;

export const globals = app.config.globalProperties

// const i18n = createI18n({
//     locale: consts.STR_DEFAULT_LANG,
//     allowComposition: true // you need to specify that!
//   })

const vuetify = createVuetify({components, directives})

app.use(vuetify)
  
app.use(VueSignaturePad);
//app.use(VueMask)
//app.directive('mask', VueMaskDirective);

// const vMaskV2 = VueMaskDirective;
// const vMaskV3 = {
//     beforeMount: vMaskV2.bind,
//     updated: vMaskV2.componentUpdated,
//     unmounted: vMaskV2.unbind
// };
// app.directive('mask', vMaskV3)

app.use(i18n)
app.component('VTimePicker', VTimePicker)
app.component('VDateInput', VDateInput)
app.use(router)

app.use(store)
app.use(VMask)

app.mount('#app')
